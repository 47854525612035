import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import { proxyApi } from "../utilities/proxy-api";
import { ApiRequest } from "../models/proxy-api-request";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { updateShopObject } from "../redux/actions/shopActions";
import { encryptPromocode, getPaymentMethodType, goTo } from "../utilities/utils";
import { ApiErrorCode } from "../utilities/constants";

const USPaymentThankYou = ({ location }) => {
  let shopDetails = useSelector((state: RootState) => state.shop);
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);

  const [loading, setLoading] = useState(true);
  const encodedUSPromocode = shopDetails.usPromocode ? encryptPromocode(shopDetails.usPromocode) : '';
  useEffect(() => {
    const completeCheckoutRequest = new ApiRequest({
      account_id: shopDetails.us_i_account,
      delivery_complete: !shopDetails.delivery_needed || shopDetails.simcard_no.length > 0,
      is_esim: shopDetails.esim,
      plintron_plan_id: shopDetails.us_plintron_plan_id,
      shipping_id: shopDetails.us_shipping_i_subscription,
      sim_card_number: shopDetails.simcard_no,
      email: shopDetails.email,
      activation_date: shopDetails.us_activation_date,
      payment_method: getPaymentMethodType(shopDetails.payment_method),
      customer_id: shopDetails.us_i_customer,
      promo_code: encodedUSPromocode
    });
    proxyApi.checkout.completeCheckout(completeCheckoutRequest).then(res => {
      if (!res.success && res.error) {
        console.error(res.error);
        if (res.error.code === ApiErrorCode.PaymentNotCompleted.toString()) {
          goTo("/404");
          return;
        }
        setLoading(false);
        return;
      }
      dispatchShop({ us_i_rep: null });
      setLoading(false);
    }).catch(err => {
      console.error(err);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading &&
        <PageLayout hideHeader={true} hideFooter={true} containerPadding="0px">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mb-0 mt-3">Finalizing Account Activation... </p>
            </div>
          </div>
        </PageLayout>
      }
      {!loading &&
        <PageLayout>
          <Container>
            <Row>
              <Col>
                <div style={{ textAlign: "center", paddingTop: "80px" }}>
                  <Title>You Activation is Completed!</Title>
                  <PaddedImage width={"100px"} src="/images/checkout_Success.png" alt={"successful payment"} />
                  <p>Thank you for choosing PhoneBox as your wireless service provider.</p>
                  <p>We Have sent you an email with your account and payment information. Please check the email for more details.</p>
                  <p>Your Phone Number will be provided via Email and SMS once the SIM card is activated.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </PageLayout>
      }
    </>
  )
}

export default USPaymentThankYou;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PaddedImage = styled(Image)`
    padding-top:20px;
    padding-bottom:30px;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    height: 100%;
    line-height: 40px;
    color: #0494ca;
`;

export const Head = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{
        __html: `
          (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `
      }}/>
    </>
  )
}